import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";


function PolitiqueConfidentialite() {
    return (
        <div className="landing-page-component">
            <FrontHeader />
            <div className="page-content pb-5 pb-5">
                <h1 className="custom-page-title">
                    Politique de confidentialité
                </h1>
                <div className="container pb-4">
                    <div className="custom-politique-page-content pt-5">
                        <h3 className="custom-sm-title pb-1 pb-4">

                            Politique de Confidentialité
                        </h3>
                        <p className="update-date-content">
                            Dernière mise à jour : 17 Mai 2024
                        </p>
                        <div className="content-all-politique pt-4 pb-3">
                            <div className="content-politique-item">
                                <h3 className="custom-sm-title pb-4">
                                    1. Introduction
                                </h3>
                                <div className="custom-sm-value">

                                    Bienvenue sur le site de vente en ligne de TOROBEE DISTRIBUTION. La protection de vos données personnelles est une priorité pour nous.
                                    Cette Politique de Confidentialité vise à vous informer sur la manière dont nous collectons, utilisons, partageons et protégeons vos informations.

                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-2">
                                    2. Informations Collectées
                                </h3>
                                <div className="custom-sm-value pb-4">
                                    Nous collectons les informations personnelles des utilisateurs de la manière suivante :
                                </div>
                                <div className="custom-sm-value">

                                    Nous collectons des informations lorsque vous effectuez des achats sur notre site, vous vous inscrivez à notre newsletter,
                                    participez à des enquêtes ou communiquez avec nous. Les informations collectées peuvent inclure votre nom, adresse e-mail, adresse de livraison,
                                    informations de paiement, historique d'achat, etc.

                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">
                                    3. Utilisation des informations
                                </h3>
                                <div className="custom-sm-value">

                                    Nous utilisons vos informations pour traiter vos commandes, personnaliser votre expérience, améliorer notre site,
                                    vous informer sur des offres spéciales et répondre à vos demandes de service à la clientèle.

                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">

                                    4.  Protection des Informations
                                </h3>
                                <div className="custom-sm-value">
                                    La sécurité de vos informations personnelles est essentielle pour nous. Nous mettons en œuvre des mesures de sécurité techniques,
                                    physiques et administratives pour protéger vos données contre tout accès non autorisé, perte ou modification.

                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">

                                    5. Partage des Informations
                                </h3>
                                <div className="custom-sm-value">
                                    Nous ne vendons, n'échangeons ni ne louons vos informations personnelles à des tiers. Cependant, dans le cadre de nos activités commerciales,
                                    nous pouvons partager vos informations avec des partenaires de confiance qui nous aident à exploiter le site et à vous servir au mieux.

                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">

                                    6. Cookies et Technologies Similaires
                                </h3>
                                <div className="custom-sm-value">
                                    Nous utilisons des cookies et d'autres technologies similaires pour améliorer votre expérience de navigation sur notre site. Vous pouvez gérer
                                    les préférences liées aux cookies dans les paramètres de votre navigateur.

                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">

                                    7. Accès et Contrôle de Vos Informations
                                </h3>
                                <div className="custom-sm-value">
                                    Vous avez le droit d'accéder à vos informations personnelles, de les corriger, de les mettre à jour ou de les supprimer. Pour exercer ces droits
                                    ou poser des questions sur notre politique de confidentialité, veuillez nous contacter à <em>assanekanedisquaire@yahoo.fr</em>.
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">
                                    7. Modifications de la Politique de Confidentialité
                                </h3>
                                <div className="custom-sm-value">
                                    Nous nous réservons le droit de mettre à jour cette Politique de Confidentialité à tout moment. Les changements seront publiés sur cette page, et la date de la dernière mise à jour sera indiquée.
                                    <br /><br />
                                    En utilisant notre site, vous consentez à notre Politique de Confidentialité. Nous vous encourageons à la consulter régulièrement pour rester informé sur la manière dont nous protégeons vos données personnelles.
                                </div>
                            </div>
                        </div>
                        <div className="custom-sm-value pt-5 pb-3">
                            Merci de faire confiance à TOROBEE DISTRIBUTION pour vos besoins musicaux.
                        </div>

                    </div>
                </div>
            </div>
            <FrontFooter />
        </div>
    )
}

export default PolitiqueConfidentialite