import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import illustration from "../../../assets/images/appImages/illustration.png";
import { TeamData } from "./data";
import { Link } from "react-router-dom";

const CustomBeautyTitle = ({ title }: { title: string }) => {
  return <h2 className="custom-esb-title">{title}</h2>;
};

const TeamCard = ({
  photo,
  name,
  role,
  twitter,
  facebook,
  linkedin,
}: {
  photo?: string;
  name: string;
  role: string;
  twitter?: string;
  facebook?: string;
  linkedin?: string;
}) => {
  return (
    <div className="team-card">
      <span className="photo-wrapper">
        <img loading="lazy" src={photo} alt="photo" />
      </span>
      <span className="name">{name}</span>
      <span className="role">{role}</span>
    </div>
  );
};

function WhoWeAre() {
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content who-we-are-page pb-5 pb-5">
        <h1 className="custom-page-title">Qui sommes-nous ?</h1>
        <div className="who-we-are">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-4">
                <div className="illustration-container">
                  <img loading="lazy" src={illustration} alt="image" />
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <CustomBeautyTitle title="Qui sommes nous?" />
                <h3 className="custom-title">Torobee</h3>
                <div className="custom-content">
                  Torobee, le seul rescapé des disquaires de Dakar. <br />
                  <br />
                  <strong>
                    {" "}
                    Magasin de Musique| •CD| •Vinyls| •Cassettes et accessoires
                  </strong>
                  <br />
                  <br />
                  Nous aidons les artistes du monde entier à vendre leurs
                  albums.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="plateform-founders">
          <div className="container">
            <CustomBeautyTitle title="Qui sommes nous?" />
            <h3 className="custom-title">Notre histoire</h3>
            <div className="row pt-4">
              <div className="col-md-12">
                <div className="founder-card">
                  <p className="description">
                    Il y a plus de <strong>deux décennies, Assane Kane</strong>{" "}
                    entamait son parcours dans le monde de la musique. Armé
                    d'une simple caisse de cassettes, il sillonnait le
                    Dakar-Plateau à la recherche de mélomanes intéressés par sa
                    passion.
                    <br />
                    <br />
                    Lorsque les premiers CD firent leur apparition, Assane opta
                    pour l'originalité plutôt que les copies pirates. C'est à
                    cette époque qu'il prit la décision audacieuse de s'associer
                    avec son frère, <strong>Abdoul Aziz Sall</strong>, en
                    2008/2009. Ensemble, ils entreprirent la collecte et la
                    sélection minutieuse de disques vinyles, bâtissant au fil du
                    temps une collection impressionnante. Au cœur de leur trésor
                    musical, la majorité des vinyles provient de la riche
                    période de la musique africaine, des années 60 aux années
                    80. Toutefois, leur collection s'étend bien au-delà,
                    englobant des vinyles du monde entier, y compris des sorties
                    récentes d'artistes renommés.
                    <br />
                    <br />
                    Leur boutique, nichée dans le{" "}
                    <strong>jardin de l'Institut Français Dakar</strong>,
                    devient un lieu enchanteur où l'on peut plonger à tout
                    moment dans les rythmes envoûtants des plus grands
                    orchestres de l'âge d'or du Sénégal. Des noms tels que Star
                    Number One de Dakar, Star Band de Dakar, Number One de
                    Dakar, Orchestra Baobab, Etoile de Dakar, Super Diamano de
                    Dakar, Ucas Jazz Band De Sedhiou, mais aussi des trésors
                    venus de la Gambie (Guelewar Band of Banjul), de la Guinée
                    (Bembeya Jazz), du Bénin (Tout Puissant OK Jazz, Orchestre
                    Polyrythmo), du Mali (Les Ambassadeurs du Mali), et bien
                    d'autres encore.
                    <br />
                    <br />
                    C'est un <strong>voyage musical</strong> à travers l'Afrique
                    et le monde entier, où chaque vinyle raconte une histoire,
                    et chaque mélodie résonne comme un appel aux passionnés et
                    collectionneurs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-team">
          <div className="container">
            <CustomBeautyTitle title="Team" />
            <h3 className="custom-title">Notre équipe</h3>
            <div className="row pt-4">
              {!!TeamData &&
                TeamData.map((member, key) => {
                  return (
                    <div className="col-md-3 mb-4 card-wrapper">
                      <TeamCard
                        photo={member.photo}
                        name={member.name}
                        role={member.role}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
}

export default WhoWeAre;
