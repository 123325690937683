import photo1 from "../../../assets/images/appImages/member-1.png";
import photo2 from "../../../assets/images/appImages/member-2.png";
import photo3 from "../../../assets/images/appImages/member-3.png";

export const TeamData = [
  {
    id: 1,
    photo: photo1,
    name: "Assane KANE",
    role: "Directeur",
  },
  {
    id: 2,
    photo: photo2,
    name: "Abdou Aziz SALL",
    role: "Associer/ Vendeur",
  },
  {
    id: 3,
    photo: photo3,
    name: "Mamadou Lamine FAYE",
    role: "Associer/ Communicateur",
  },
];
